<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md3>
          <v-card class="elevation-12">
            <v-toolbar dark color="secondary">
              <v-toolbar-title class="primary--text">ĐĂNG NHẬP VÀO BO-PORTAL</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="#474747" dark @click="loginWithGoogle">
                <v-icon left>$google</v-icon> <span class="secondary--text">Google</span>
              </v-btn>
            </v-toolbar>
          </v-card>
      </v-flex>
    </v-layout>
    <loading :status="loading" />
  </v-container>
</template>

<script>
import { ThongBao, GoogleAuth, MyAccount } from '@/helpers';
import loading from '@/components/base/loading.vue'
import router from '@/router'
export default {
  components:{
    loading,
  },
  data () {
    return {
      loading:false,
    }
  },
  methods:{
    loginWithGoogle () {
      this.loading = true
      this.$gAuth
        .signIn()
        .then(GoogleUser => {
          const user = {
            name: GoogleUser.getBasicProfile().getName(),
            email: GoogleUser.getBasicProfile().getEmail(),
            profileImage: GoogleUser.getBasicProfile().getImageUrl()
          }
          this.$store.commit('setLogin',user)
          return GoogleUser.getAuthResponse().id_token;
        })
        .then(GoogleAuth)
        .then(resp=>{
          const {token} = resp
          if(!token) return ThongBao.Error("Không thể đăng nhập. Hãy thử lại !!!")
          this.$store.commit('doLogin',token);
          ThongBao.Success("Đã đăng nhập. Vui lòng đợi ...")
          setTimeout(()=>{
            router.push('/')
            MyAccount().then(json=>{
              if(json.data){
                this.$store.commit('setUserData',json.data);
              }
            })
          },500)
        })
        .catch(e => {
          if(e){
            if(e.response){
              if(e.response.data.error){
                ThongBao.Error(e.response.data.error)
              }
            }else{
              if(e.error !== "popup_closed_by_user"){
                const msg = e.error || "Không thể đăng nhập. Hãy thử lại !!!"
                ThongBao.Error(msg)
              }
            }
          }
        })
        .finally(()=>{
          this.loading = false;
        })
    },
  }
}
</script>

<style>

</style>